import React, { useState, memo } from "react";
import "./style.scss";
// import { Collapse } from "reactstrap";
import ReactPlayer from "react-player";
import Button from "react-bootstrap/Button";
import VideoPlayer from "react-video-js-player";
import ReactMarkdown from "react-markdown";
import rehypeRaw from "rehype-raw";
import faq1 from "../../assets/faq1.svg";
import videoPic from "../../assets/video place holder 1.svg";
import placeHolder from "../../assets/blogSplaceHolder.svg";
import { Share } from "./Share";
import { SlideDropdown } from "../SlideDown";

const FaqCard = (props) => {
	const [collapse, setCollapsed] = useState(false);
	const toggle = (e) => {
		e.preventDefault();
		setCollapsed((prevState) => !prevState);
	};

	// let [edit, setEdit] = useState(false);

	const { data, editHandler } = props;

	let input = data.markdown;

	const formatDate = (date) => {
		let d = new Date(date);
		let month = d.getMonth() + 1;
		let day = d.getDate();
		let year = d.getFullYear();
		let formattedDate = `${month}/${day}/${year} `;
		return formattedDate;
	};

	return (
		<div>
			<div className="faq-card-wrapper">
				<div className="faq-card container-fluid">
					<div className="faq-title-wrapper">{data.article}</div>
					<div className="faq-btn-container mt-16">
						<div className="faq-btn-wrapper">
							<div
								className={collapse ? "toggle-btn-rev" : "toggle-btn"}
								onClick={toggle}>
								<svg
									width="18"
									height="19"
									viewBox="0 0 18 19"
									fill="none"
									xmlns="http://www.w3.org/2000/svg">
									<path
										d="M1.00055 1.12076L9.1233 17.3394L17.0001 0.998776"
										stroke="white"
										strokeWidth="2"
										strokeLinecap="round"
										strokeLinejoin="round"
									/>
								</svg>
							</div>
							<div className="publish-date">
								Published on {formatDate(data.datePublished)}
							</div>
							<div
								onClick={() => {
									editHandler(data);
								}}
								className="cursor-pointer pr-20"
								role="button">
								<Button variant="secondary">Edit</Button>
							</div>
							<Share {...data} key={data._id} />
						</div>
					</div>
					<SlideDropdown open={collapse}>
						<div className="article-body-wrapper mt-45">
							<div>{data.description}</div>
						</div>
						{data.pic.map((data, index) => {
							return (
								<div className="mt-10  img-wrapper" key={index}>
									<div className="w-100">
										<img
											height="100%"
											width="100%"
											className="container-fluid article-image"
											src={data.url}
											alt={data.title}
											onError={(e) => {
												e.target.onerror = null;
												e.target.src = placeHolder;
											}}
										/>
									</div>
									<div className="mt-4">
										<div className="pic-title">{data.title}</div>
										<div className="pic-comment">{data.description}</div>
									</div>
								</div>
							);
						})}
						{data.video.map((data, index) => {
							return (
								<div className="video-wrapper mt-10" key={index}>
									<div className="w-100">
										<div className="article-video">
											<iframe
												width="853"
												height="480"
												src={data.url ?? "https://www.youtube.com/embed/linlz7-Pnvw"}
												allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
												allowFullScreen
												title="Embedded youtube"
											/>
										</div>
									</div>
									<div className="mt-19">
										<div className="pic-title mt-4">{data.title}</div>
										<div className="pic-comment">{data.description}</div>
									</div>
								</div>
							);
						})}
					</SlideDropdown>
				</div>
			</div>
			<div className="img-fluid d-none">
				<ReactMarkdown
					source={input}
					rehypePlugins={[rehypeRaw]}
					skipHtml={false}
					children={input}
					className="React-Markdown"
				/>
			</div>
		</div>
	);
};

export default memo(FaqCard);
