import React, { useEffect, useState, memo } from "react";
import "./style.scss";
import FaqCard from "../FaqCard";
import { useSelector } from "react-redux";
import { faqFeatureKey } from "../../store/featureKeys";

const FaqList = (props) => {
	let { loading, error, faqData } = useSelector((state) => {
		return state[faqFeatureKey];
	});

	let { article, ascending, editHandler } = props;
	let [data, setData] = useState([]);

	useEffect(() => {
		let filteredData =
			faqData &&
			faqData.data.filter((product) => {
				return product.article.toLowerCase().includes(article.toLowerCase());
			});
		if (!ascending) {
			setData(filteredData);
		} else {
			setData(filteredData.reverse());
		}
	}, [article, ascending, faqData]);

	let [showData, setShowData] = useState(5);
	let handleVisibility = () => {
		let diff = data.length - showData;
		if (diff === 0) {
			setShowData((prevState) => 5);
		} else if (diff > 4) {
			setShowData((prevState) => prevState + 5);
		} else {
			setShowData((prevState) => data.length);
		}
	};

	return (
		<div className="faq-list-wrapper">
			{data.length === 0 ? (
				<h1>No data found</h1>
			) : (
				data.length !== 0 &&
				data.slice(0, showData).map((datas, index) => {
					return (
						<FaqCard
							key={datas._id ?? index}
							data={datas}
							editHandler={editHandler}
						/>
					);
				})
			)}
			{/* (
				data.length !== 0 &&
				data
					.reverse()
					.slice(0, showData)
					.map((datas, index) => {
						return <FaqCard key={datas._id ?? index} data={datas} />;
					})
			) */}
			<center>
				<button className="btn btn-sm btn-dark" onClick={() => handleVisibility()}>
					{data.length == showData ? "Show less" : "Show more"}
				</button>
			</center>
		</div>
	);
};

export default memo(FaqList);
