import React from "react";
import { Link } from "react-router-dom";
import { Navbar, Container } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { authFeatureKey } from "../../store/featureKeys";
import logo from "../../assets/index.png";
import "./style.scss";
import { LOGOUT_USER } from "../../types/auth";
import { Icon } from "@material-ui/core";

let TopStrip = (props) => {
	let { isAuthenticated, user } = useSelector((state) => {
		return state[authFeatureKey];
	});
	let dispatch = useDispatch();
	const handleLogout = (e) => {
		dispatch({ type: LOGOUT_USER });
		props.history("/login");
	};
	return (
		<>
			<Navbar bg="dark" variant="dark">
				<Container
					className="d-flex py-2 px-3 align-items-center justify-content-center"
					fluid={true}>
					<div className="d-flex align-items-center">
						<div className="w-100 d-flex justify-content-center">
							<div className="d-inline-block text-white">
								<svg
									xmlns="http://www.w3.org/2000/svg"
									className="icon-dimension text-white"
									fill="none"
									viewBox="0 0 24 24"
									stroke="currentColor">
									<path
										strokeLinecap="round"
										strokeLinejoin="round"
										strokeWidth={2}
										d="M20 13V6a2 2 0 00-2-2H6a2 2 0 00-2 2v7m16 0v5a2 2 0 01-2 2H6a2 2 0 01-2-2v-5m16 0h-2.586a1 1 0 00-.707.293l-2.414 2.414a1 1 0 01-.707.293h-3.172a1 1 0 01-.707-.293l-2.414-2.414A1 1 0 006.586 13H4"
									/>
								</svg>
								<span className="text-white">Admin dashboard</span>
								<Icon role="button" className="text-white pl-5" onClick={handleLogout}>
									<svg
										xmlns="http://www.w3.org/2000/svg"
										width="16"
										height="16"
										fill="currentColor"
										className="bi bi-box-arrow-right"
										viewBox="0 0 16 16">
										<path
											fillRule="evenodd"
											d="M10 12.5a.5.5 0 0 1-.5.5h-8a.5.5 0 0 1-.5-.5v-9a.5.5 0 0 1 .5-.5h8a.5.5 0 0 1 .5.5v2a.5.5 0 0 0 1 0v-2A1.5 1.5 0 0 0 9.5 2h-8A1.5 1.5 0 0 0 0 3.5v9A1.5 1.5 0 0 0 1.5 14h8a1.5 1.5 0 0 0 1.5-1.5v-2a.5.5 0 0 0-1 0v2z"
										/>
										<path
											fillRule="evenodd"
											d="M15.854 8.354a.5.5 0 0 0 0-.708l-3-3a.5.5 0 0 0-.708.708L14.293 7.5H5.5a.5.5 0 0 0 0 1h8.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3z"
										/>
									</svg>
								</Icon>
							</div>
						</div>
					</div>
				</Container>
			</Navbar>
		</>
	);
};

export default TopStrip;
