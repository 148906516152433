import * as types from "../../types/faq";

const initialState = {
	loading: false,
	error: "",
	faqData: "",
};
const faqReducer = (state = initialState, action) => {
	const { type, payload } = action;
	switch (type) {
		case types.GET_FAQ:
			return {
				...state,
				loading: true,
			};
		case types.GET_FAQ_SUCCESS:
			return {
				...state,
				faqData: payload,
				loading: false,
			};
		case types.GET_FAQ_FAILURE: {
			return {
				...state,
				loading: false,
			};
		}
		case types.UPLOAD_FAQ:
			return {
				...state,
				loading: true,
			};
		case types.UPLOAD_FAQ_SUCCESS:
			return {
				...state,
				// faqData: payload,
				loading: false,
			};
		case types.UPLOAD_FAQ_FAILURE: {
			return {
				...state,
				loading: false,
			};
		}
		case types.UPDATE_FAQ:
			return {
				...state,
				loading: true,
			};
		case types.UPDATE_FAQ_SUCCESS:
			return {
				...state,
				// faqData: [...state.faqData, payload],
				loading: false,
			};
		case types.UPDATE_FAQ_FAILURE: {
			return {
				...state,
				loading: false,
			};
		}
		default:
			return state;
	}
};
export default faqReducer;
