// Function to fetch data from local storage
export const getItemFromLocalStorage = (key) => {
	try {
		const storedData = localStorage.getItem(key);
		if (storedData) {
			return JSON.parse(storedData);
		}
		return null;
	} catch (error) {
		console.error("Error fetching data from local storage:", error);
		return null;
	}
};

// Function to store data in local storage
export const setItemInLocalStorage = (key, value) => {
	try {
		const serializedValue = JSON.stringify(value);
		localStorage.setItem(key, serializedValue);
	} catch (error) {
		console.error("Error storing data in local storage:", error);
	}
};

// Function to remove data from local storage
export const removeItemFromLocalStorage = (key) => {
	try {
		localStorage.removeItem(key);
	} catch (error) {
		console.error("Error removing data from local storage:", error);
	}
};
