import React from "react";

const SignUp = (props) => {
	return (
		<React.Fragment>
			<div>Hii from Signup</div>
		</React.Fragment>
	);
};
export default SignUp;
