export const GET_FAQ = "GET_FAQ";
export const GET_FAQ_SUCCESS = "GET_FAQ_SUCCESS";
export const GET_FAQ_FAILURE = "GET_FAQ_FAILURE";

export const UPLOAD_FAQ = "UPLOAD_FAQ";
export const UPLOAD_FAQ_SUCCESS = "UPLOAD_FAQ_SUCCESS";
export const UPLOAD_FAQ_FAILURE = "UPLOAD_FAQ_FAILURE";

export const UPDATE_FAQ = "UPDATE_FAQ";
export const UPDATE_FAQ_SUCCESS = "UPDATE_FAQ_SUCCESS";
export const UPDATE_FAQ_FAILURE = "UPDATE_FAQ_FAILURE";

export const GET_YOUTUBE_VIDEO = "GET_YOUTUBE_VIDEO";
export const GET_YOUTUBE_VIDEO_SUCCESS = "GET_YOUTUBE_VIDEO_SUCCESS";
export const GET_YOUTUBE_VIDEO_FAILURE = "GET_YOUTUBE_VIDEO_FAILURE";
