import "./App.css";
import React, { useCallback, useEffect } from "react";
import Home from "./components/Home";
import Login from "./components/Login";
import Signup from "./components/Signup";
import Markdown from "./components/Makdown/Markdown";
import { useDispatch, useSelector } from "react-redux";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { getItemFromLocalStorage } from "./utils/localStorageUtils";
import * as types from "./types/auth";

function App() {
	const dispatch = useDispatch();

	const getUserDetails = useCallback(async () => {
		let data = await getItemFromLocalStorage("user");
		if (data) {
			dispatch({ type: types.LOGIN_USER_SUCCESS, payload: { data: data } });
		}
	}, [dispatch]);

	useEffect(() => {
		getUserDetails();
	}, [getUserDetails]);

	return (
		<Router>
			<Switch>
				<Route exact path="/" component={Home} />
				<Route path="/login" component={Login} />
				<Route path="/sign-up" component={Signup} />
				<Route path="/mark-down" component={Markdown} />
			</Switch>
		</Router>
	);
}

export default App;
