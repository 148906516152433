import * as types from "../../types/faq";
import axios from "axios";
import { base_uri } from "../../constants";
import { toast } from "react-toastify";

export const getFaqData = () => {
	return async (dispatch) => {
		try {
			dispatch({ type: types.GET_FAQ });
			let response = await axios.get(`${base_uri}/faq`);
			if (response.status === 201) {
				dispatch({ type: types.GET_FAQ_SUCCESS, payload: response.data });
			} else {
				toast.error("ohh error occurred in getting the faq data");
				dispatch({
					type: types.GET_FAQ_FAILURE,
					payload: "ohh error occurred in getting the faq data",
				});
			}
		} catch (e) {
			toast.error("ohh error occurred in getting the faq data");
			dispatch({ type: types.GET_FAQ_FAILURE, payload: e.message });
		}
	};
};

export const uploadFaq = (faqDetail, hideForm, reset) => {
	return async (dispatch) => {
		try {
			dispatch({ type: types.UPLOAD_FAQ });
			let response = await axios.post(`${base_uri}/faq`, faqDetail, {
				headers: {
					"Content-Type": "multipart/form-data",
				},
			});
			if (response.status === 201) {
				dispatch({ type: types.UPLOAD_FAQ_SUCCESS, payload: response.data.data });
				reset();
				hideForm();
				toast.success("Faq uploaded successfully");
				dispatch(getFaqData());
			} else {
				toast.error("ohh error occurred in uploading the faq");
				dispatch({
					type: types.UPLOAD_FAQ_FAILURE,
					payload: "ohh error occurred in uploading the faq",
				});
			}
		} catch (e) {
			toast.error("ohh error occurred in uploading the faq");
			dispatch({ type: types.UPLOAD_FAQ_FAILURE, payload: e.message });
		}
	};
};

export const updateFaq = (faqDetail, id, hideForm, reset) => {
	return async (dispatch) => {
		try {
			dispatch({ type: types.UPDATE_FAQ });
			let response = await axios.put(`${base_uri}/faq/${id}`, faqDetail);
			if (response.status === 201) {
				dispatch({ type: types.UPDATE_FAQ_SUCCESS, payload: response.data.data });
				reset();
				hideForm();
				dispatch(getFaqData());
				toast.success("Faq updated successfully");
			} else {
				toast.error("ohh error occurred in UPDATING the faq");
				dispatch({
					type: types.UPDATE_FAQ_FAILURE,
					payload: "ohh error occurred in UPDATING the faq",
				});
			}
		} catch (e) {
			console.log(e.message, "error");
			toast.error("ohh error occurred in UPDATING the faq");
			dispatch({ type: types.UPDATE_FAQ_FAILURE, payload: e.message });
		}
	};
};
