import React, { useEffect, useState, memo } from "react";
import Layout from "../Layout";
import { useDispatch, useSelector } from "react-redux";
import "./style.scss";
import FaqList from "../FaqList";
import AddFaq from "../AddFaq";
import EditFaq from "../EditFaq";
import * as faqAction from "../../store/actions/faqAction";
import { faqFeatureKey, authFeatureKey } from "../../store/featureKeys";
import { DropdownButton, Dropdown, ButtonGroup } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { CircularProgress } from "@material-ui/core";

const Home = (props) => {
	const [showForm, setShowForm] = useState(false);
	const [editForm, setEditForm] = useState(false);
	const [editData, setEditData] = useState(null);

	const [way, setWay] = useState(false);
	const { loading, error, faqData } = useSelector((state) => {
		return state[faqFeatureKey];
	});
	const { isAuthenticated, user } = useSelector((state) => {
		return state[authFeatureKey];
	});
	const dispatch = useDispatch();
	const history = useHistory();
	let [article, setArticle] = useState("");

	useEffect(() => {
		dispatch(faqAction.getFaqData());
	}, [article, dispatch]);

	useEffect(() => {
		if (!isAuthenticated) {
			history.push("/login");
		}
	}, [isAuthenticated]);

	const editHandler = (data) => {
		setEditData(data);
		setShowForm(true);
		setEditForm(true);
		scrollToTop();
	};

	function scrollToTop() {
		if (editForm) {
			window.scrollTo({
				top: 0,
				behavior: "smooth",
			});
		}
	}

	return (
		<>
			{loading && (
				<div className="zIndexBackdrop">
					<CircularProgress color="inherit" />
				</div>
			)}
			<Layout>
				<div className="home-page-wrapper">
					{showForm ? (
						<div>
							{editForm ? (
								<EditFaq
									hideForm={() => {
										setShowForm((prevState) => !prevState);
									}}
									data={editData}
									setEditForm={setEditForm}
								/>
							) : (
								<AddFaq
									hideForm={() => {
										setShowForm((prevState) => !prevState);
									}}
								/>
							)}
						</div>
					) : (
						<div className="add-new-article-btn-wrapper">
							<div
								onClick={(e) => {
									e.preventDefault();
									setEditForm(false);
									setEditData(null);
									setShowForm((prevState) => !prevState);
								}}
								className="add-new-article-btn">
								ADD NEW ARTICLE
							</div>
						</div>
					)}
					<div className="border-b-custom"></div>
					<div className="faq-wrapper">
						<div className="faq">
							<span className="faq-span">FAQ</span>
						</div>
						<div className="first-input-wrapper m-0 w-75 ">
							<input
								onChange={(e) => setArticle(e.target.value)}
								type="text"
								className="input-holder"
								placeholder="Search by keywords"
							/>
						</div>
					</div>
					<div className="mt-30 keep-center">
						<DropdownButton
							as={ButtonGroup}
							key="end"
							id={`dropdown-button-drop-end`}
							drop="end"
							variant="secondary"
							title={` Sort By `}>
							<div onClick={() => setWay(true)}>
								<Dropdown.Item eventKey="3">Latest</Dropdown.Item>
							</div>
							<div onClick={() => setWay(false)}>
								<Dropdown.Item eventKey="3">Oldest</Dropdown.Item>
							</div>
							{/* <div>
								<Dropdown.Item eventKey="3">Dummy</Dropdown.Item>
							</div>
							<div>
								<Dropdown.Item eventKey="3">Dummy</Dropdown.Item>
							</div> */}
						</DropdownButton>
					</div>
					<br />
					<div className="border-b-custom"></div>
					<FaqList article={article} ascending={way} editHandler={editHandler} />
					<div className="p-5"></div>
				</div>
			</Layout>
		</>
	);
};
export default memo(Home);
