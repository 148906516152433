import React, { memo } from "react";
import { uid } from "uid";

export default memo(function VideoAdd({
	register,
	errors,
	defaultFieldVideoURL,
	videoUrls,
	setVideoUrls,
	appendVideoURL,
	removeVideoURL,
}) {
	const addField = () => {
		let id = uid(16);
		let newDefault = { ...defaultFieldVideoURL, _id: id };
		setVideoUrls([...videoUrls, newDefault]);
		appendVideoURL(newDefault);
	};
	const removeField = (index) => {
		removeVideoURL(index);
		setVideoUrls((prevFields) => {
			const updatedFields = [...prevFields];
			updatedFields.splice(index, 1);
			return updatedFields;
		});
	};

	return (
		<div>
			<div className="mt-10 ">
				<label className="font-18"> Video URL:</label>
			</div>
			<div className="border-1 p-10">
				{videoUrls.map((field, index) => (
					<div key={index}>
						<div className="flex justify-between">
							<span>{index + 1}#</span>
							<button
								className="btn btn-sm btn-danger float-right"
								type="button"
								onClick={() => removeField(index)}>
								-
							</button>
						</div>
						<div className="p-10 mx-40 ">
							<input
								type="hidden"
								value={field._id}
								{...register(`videoUrls.${index}._id`, {
									onChange: (e) => {
										setVideoUrls((prevFields) => {
											const updatedFields = [...prevFields];
											updatedFields[index]._id = e.target.value;
											return updatedFields;
										});
									},
								})}
							/>
							<div className="mt-10 ">
								<label className="font-18"> Title:</label>
							</div>
							<div className="input-wrapper">
								<input
									type="text"
									placeholder="Video Title...."
									className="input"
									value={field.title}
									{...register(`videoUrls.${index}.title`, {
										onChange: (e) => {
											setVideoUrls((prevFields) => {
												const updatedFields = [...prevFields];
												updatedFields[index].title = e.target.value;
												return updatedFields;
											});
										},
									})}
								/>
							</div>
							<div className="text-danger text-sm ml-3 pt-1">
								{errors?.videoUrls?.[index]?.title && (
									<span>{errors.videoUrls[index].title.message}</span>
								)}
							</div>
							<div className="mt-10 ">
								<label className="font-18"> Description:</label>
							</div>
							<div className="input-wrapper">
								<textarea
									className="input"
									value={field.description}
									placeholder="Video Description...."
									{...register(`videoUrls.${index}.description`, {
										onChange: (e) => {
											setVideoUrls((prevFields) => {
												const updatedFields = [...prevFields];
												updatedFields[index].description = e.target.value;
												return updatedFields;
											});
										},
									})}></textarea>
							</div>
							<div className="text-danger text-xs ml-3 pt-1 ">
								{errors?.videoUrls?.[index]?.description && (
									<span>{errors.videoUrls[index].description.message}</span>
								)}
							</div>
							<div className="mt-10 ">
								<label className="font-18"> Video URL:</label>
							</div>
							<div className="input-wrapper">
								<input
									type="text"
									placeholder="Video URL...."
									className="input"
									value={field.url}
									{...register(`videoUrls.${index}.url`, {
										onChange: (e) => {
											setVideoUrls((prevFields) => {
												const updatedFields = [...prevFields];
												updatedFields[index].url = e.target.value;
												return updatedFields;
											});
										},
									})}
								/>
							</div>
							<div className="text-danger text-xs ml-3 pt-1">
								{errors?.videoUrls?.[index]?.url && (
									<span>{errors.videoUrls[index].url.message}</span>
								)}
							</div>
						</div>
					</div>
				))}

				<div className="p-10 mb-40">
					<button
						className="btn btn-sm btn-primary ml-10 float-right"
						type="button"
						onClick={() => addField()}>
						+ Add
					</button>
				</div>
			</div>
		</div>
	);
});
