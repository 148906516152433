import {combineReducers} from "redux";
import * as keys from "./featureKeys"
import  authReducer from './reducers/authReducers';
import layoutReducer from './reducers/layoutReducers';
import faqReducer from './reducers/faqReducer';

export const rootReducer = combineReducers({
    [keys.authFeatureKey] : authReducer,
    [keys.layoutFeatureKey] : layoutReducer,
    [keys.faqFeatureKey] : faqReducer
});

