
import React ,{useEffect,useState} from "react";
// import {useSelector,useDispatch} from "react-redux";
import { Col, Row } from "reactstrap";
import Sidebar from "../Sidebar";
import TopStrip from "./topstrip";

const styles = {
    contentDiv: {
        margin:"0",
    },
    contentMargin: {
        // border:"1px solid red",
        margin:"0 0",
        padding:"0 ",
        width:"83%"
    },
    borderCustom:{
        padding: "0",
        margin:"0",
    }
};

const Layout = ({children})=>{
    return(
        <div style={styles.borderCustom} className="w-100">
            <div>
                <TopStrip/>
            </div>
            <div className="w-100 d-flex m-0 ">
                <div style={styles.contentDiv} className="sidebar-compo-wrapper">
                    {/*<Sidebar/>*/}
                </div>
                <Col style={styles.contentMargin}>
                    <div className="w-100">
                        <div className="width-res">
                            {children}
                        </div>
                    </div>
                </Col>
            </div>
        </div>
    )
}

export default Layout

