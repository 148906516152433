import React, {useState} from "react";
import ReactMarkdown from 'react-markdown'
import rehypeRaw from "rehype-raw";
import './markDown.scss';

const Markdown = ()=>{

    const [markDown , setMarkDown]=useState('Heading');

    return (
        <div className='markdown-container'>
            <textarea
            value={markDown}
            onChange={(e)=>setMarkDown(e.target.value)}
            />
            <ReactMarkdown source={markDown}  skipHtml={false} className='React-Markdown' children={markDown}/>
        </div>
    )

};

export default Markdown;