import React from "react";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Popover from "react-bootstrap/Popover";
import {
	FacebookIcon,
	FacebookShareButton,
	TwitterShareButton,
	TwitterIcon,
	LinkedinShareButton,
	LinkedinIcon,
	PinterestShareButton,
	PinterestIcon,
	RedditShareButton,
	RedditIcon,
	TumblrShareButton,
	TumblrIcon,
	TelegramShareButton,
	TelegramIcon,
	WhatsappShareButton,
	WhatsappIcon,
	EmailShareButton,
	EmailIcon,
} from "react-share";
import { client_uri } from "../../constants";

export const Share = ({ _id, article, pic, description }) => {
	const url = `${client_uri}/faq/${_id}/${article}`;
	let imagePath = "";
	if (pic && pic.length > 0 && pic[0].url) {
		imagePath = pic[0].url;
	}

	const popover = (
		<Popover>
			<Popover.Header as="h3">Share {article}</Popover.Header>
			<Popover.Body>
				<div className="share-body">
					<FacebookShareButton url={url} quote={article} className="share-button">
						<FacebookIcon size={32} round />
					</FacebookShareButton>

					<TwitterShareButton url={url} title={article} className="share-button">
						<TwitterIcon size={32} round />
					</TwitterShareButton>

					<PinterestShareButton url={url} media={imagePath} className="share-button">
						<PinterestIcon size={32} round />
					</PinterestShareButton>

					<LinkedinShareButton url={url} className="share-button">
						<LinkedinIcon size={32} round />
					</LinkedinShareButton>

					<RedditShareButton
						url={url}
						title={article}
						windowWidth={660}
						windowHeight={460}
						className="share-button">
						<RedditIcon size={32} round />
					</RedditShareButton>

					<TumblrShareButton url={url} title={article} className="share-button">
						<TumblrIcon size={32} round />
					</TumblrShareButton>

					<TelegramShareButton url={url} title={article} className="share-button">
						<TelegramIcon size={32} round />
					</TelegramShareButton>

					<WhatsappShareButton
						url={url}
						title={article}
						separator=":: "
						className="share-button">
						<WhatsappIcon size={32} round />
					</WhatsappShareButton>

					<EmailShareButton
						url={url}
						subject={article}
						body={description}
						className="share-button">
						<EmailIcon size={32} round />
					</EmailShareButton>
				</div>
			</Popover.Body>
		</Popover>
	);

	return (
		<div className="edit-faq-btn">
			<OverlayTrigger trigger="click" overlay={popover}>
				<svg
					width="34"
					height="33"
					viewBox="0 0 34 33"
					fill="none"
					xmlns="http://www.w3.org/2000/svg">
					<path
						d="M16.746 26.8357C16.2398 26.841 15.8175 26.4692 15.7462 25.9818L15.7356 25.8462L15.5262 5.84729C15.5204 5.29503 15.9634 4.84265 16.5157 4.83687C17.0219 4.83157 17.4442 5.20338 17.5155 5.69076L17.5261 5.82635L17.7355 25.8253C17.7413 26.3775 17.2983 26.8299 16.746 26.8357Z"
						fill="white"
					/>
					<path
						d="M9.29443 14.6855C8.90886 15.0809 8.27575 15.0889 7.88033 14.7033C7.52085 14.3528 7.48157 13.7977 7.76707 13.4026L7.86247 13.2892L15.8096 5.13888C16.1611 4.77833 16.7182 4.74004 17.1134 5.02785L17.2267 5.12399L25.344 13.1061C25.7378 13.4933 25.7432 14.1265 25.3559 14.5203C25.0039 14.8783 24.4486 14.9152 24.0547 14.628L23.9418 14.5321L16.54 7.25453L9.29443 14.6855Z"
						fill="white"
					/>
				</svg>
			</OverlayTrigger>
		</div>
	);
};
