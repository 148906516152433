import React, { useEffect, useState } from "react";
import { Form, Button, Card } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { login } from "../../store/actions/authActions";
import { CircularProgress } from "@material-ui/core";

const Login = () => {
	const dispatch = useDispatch();
	const history = useHistory();
	const authSelector = useSelector((state) => state.auth);
	const [formData, setFormData] = useState({ email: "", password: "" });

	const handleInputChange = (event) => {
		const { name, value } = event.target;
		setFormData({ ...formData, [name]: value });
	};

	const handleSubmit = (event) => {
		event.preventDefault();
		dispatch(login(formData, history));
	};
	useEffect(() => {
		if (authSelector.isAuthenticated) {
			history.push("/");
		}
	}, [authSelector, history]);

	return (
		<div className="container">
			<div className="row mt-5 ">
				<div className="col-md-6 m-auto">
					<Card>
						<Card.Header>Admin Login</Card.Header>
						<Card.Body>
							<Form className="mt-2">
								{authSelector.isLoading && <CircularProgress />}
								{authSelector.errorMessage && (
									<p className="text-danger">{authSelector.errorMessage}</p>
								)}
								<Form.Group className="mb-3" controlId="formBasicEmail">
									<Form.Label>Email address</Form.Label>
									<Form.Control
										type="email"
										placeholder="Enter email"
										name="email"
										value={formData.email}
										onChange={handleInputChange}
									/>
									<Form.Text className="text-muted">
										We'll never share your email with anyone else.
									</Form.Text>
								</Form.Group>

								<Form.Group className="mb-3" controlId="formBasicPassword">
									<Form.Label>Password</Form.Label>
									<Form.Control
										type="password"
										placeholder="Password"
										name="password"
										value={formData.password}
										onChange={handleInputChange}
									/>
								</Form.Group>

								<Form.Group className="mb-3" controlId="formBasicCheckbox">
									<Form.Check type="checkbox" label="Check me out" />
								</Form.Group>

								<Button variant="primary" onClick={handleSubmit} type="submit">
									Submit
								</Button>
							</Form>
						</Card.Body>
					</Card>
				</div>
			</div>
		</div>
	);
};

export default Login;
