import * as types from "../../types/auth";
import axios from "axios";
import { base_uri } from "../../constants";

export const login = (user, history) => {
	return async (dispatch) => {
		try {
			dispatch({ type: types.LOGIN_USER });
			const result = await axios.post(`${base_uri}/users/login`, { ...user });
			if (result.status === 200) {
				console.log(result.data, "result.data");
				dispatch({ type: types.LOGIN_USER_SUCCESS, payload: result.data });
				history.push("/");
			} else {
				dispatch({ type: types.LOGIN_USER_FAILURE, payload: result.data.message });
				// alert(result.data.message);
			}
		} catch (e) {
			dispatch({ type: types.LOGIN_USER_FAILURE, payload: e.message });
			// alert(e.message);
		}
	};
};
