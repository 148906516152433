import * as types from "../../types/layout"

const initState = {
    isCollapsed: true,
};

const layoutReducers = (state=initState,action)=>{
    switch (action.type){
        case types.TOGGLE_SIDEBAR:
            return {
                ...state,
                isCollapsed: !state.isCollapsed
            }
        default : return state;
    }
}

export default layoutReducers;
