import * as types from "../../types/auth";
import {
	getItemFromLocalStorage,
	setItemInLocalStorage,
	removeItemFromLocalStorage,
} from "../../utils/localStorageUtils";
const initState = {
	isAuthenticated: false,
	user: getItemFromLocalStorage ? getItemFromLocalStorage("user") : {},
	token: "",
	isLoading: false,
	errorMessage: null,
};

const authReducers = (state = initState, action) => {
	switch (action.type) {
		case types.LOGIN_USER:
			return {
				...state,
				isLoading: true,
				errorMessage: null,
			};
		case types.LOGIN_USER_SUCCESS:
			setItemInLocalStorage("user", action.payload.data);
			return {
				...state,
				isAuthenticated: true,
				isLoading: false,
				user: action.payload.data,
				token: action.payload.token,
				errorMessage: null,
			};
		case types.LOGIN_USER_FAILURE:
			return {
				...state,
				isLoading: false,
				isAuthenticated: false,
				user: {},
				token: "",
				errorMessage: action.payload,
			};
		case types.LOGOUT_USER:
			removeItemFromLocalStorage("user");
			return {
				...state,
				isLoading: false,
				isAuthenticated: false,
				user: {},
				token: "",
				errorMessage: "",
			};
		default:
			return state;
	}
};

export default authReducers;
