import React, { useEffect, useState, memo } from "react";
import "./style.scss";
import { useDispatch, useSelector } from "react-redux";
import * as faqAction from "../../store/actions/faqAction";
import { faqFeatureKey } from "../../store/featureKeys";
import { useForm, useFieldArray } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import ImageAdd from "../AddImage";
import CircularProgress from "@material-ui/core/CircularProgress";
import VideoAdd from "../AddVideo";

const AddFaq = (props) => {
	const dispatch = useDispatch();
	const defaultField = { title: "", description: "", image: null, _id: "" };
	const defaultFieldVideoURL = {
		title: "",
		description: "",
		url: "",
		_id: "",
	};
	let [faq, setFaq] = useState({
		article: "",
		metaTags: "",
		description: "",
		datePublished: Date.now(),
		pic: [],
		video: "",
	});

	const { loading, error, faqData } = useSelector((state) => {
		return state[faqFeatureKey];
	});

	// form validation rules
	const validationSchema = Yup.object().shape({
		article: Yup.string().required("Article is required"),
		metaTags: Yup.string().required("Meta Tags is required"),
		description: Yup.string().required("Description is required"),
		datePublished: Yup.date()
			.required("Date is required")
			.typeError("Date is required"),
		pic: Yup.array().of(
			Yup.object().shape({
				title: Yup.string().required("Title is required"),
				description: Yup.string().required("Description is required"),
				image: Yup.mixed().required("Image is required"),
			})
		),
		videoUrls: Yup.array().of(
			Yup.object().shape({
				title: Yup.string().required("Title is required"),
				description: Yup.string().required("Description is required"),
				url: Yup.mixed().required("Video Url is required"),
			})
		),
	});

	const formOptions = { resolver: yupResolver(validationSchema) };
	const [fields, setFields] = useState([]);
	const [videoUrls, setVideoUrls] = useState([]);

	const { register, handleSubmit, setError, reset, formState, control } =
		useForm(formOptions);
	const { errors } = formState;
	const { append, remove } = useFieldArray({
		control,
		name: "fields",
	});
	const { append: appendVideoURL, remove: removeVideoURL } = useFieldArray({
		control,
		name: "videoUrls",
	});

	async function onSubmit(formFields) {
		try {
			console.log("formFields", formFields);
			const { article, metaTags, description, datePublished, pic, videoUrls } =
				formFields;
			const formData = new FormData();
			formData.append("article", article);
			formData.append("metaTags", metaTags);
			formData.append("description", description);
			formData.append("datePublished", datePublished);
			// formData.append("video", video);
			if (pic && pic.length) {
				for (const image of pic) {
					const {
						title,
						description,
						image: [file],
					} = image;
					formData.append("titles[]", title);
					formData.append("descriptions[]", description);
					formData.append("pic", file);
				}
			}
			if (videoUrls && videoUrls.length) {
				for (const item of videoUrls) {
					const { title, description, url } = item;
					formData.append("videoTitles[]", title);
					formData.append("videoDescriptions[]", description);
					formData.append("videoUrl[]", url);
				}
			}
			dispatch(faqAction.uploadFaq(formData, () => props.hideForm(), reset));
		} catch (error) {}
	}

	return (
		<>
			{loading && (
				<div className="zIndexBackdrop">
					<CircularProgress color="inherit" />
				</div>
			)}

			<div className="add-faq-wrapper">
				<form
					className="Faq-form"
					onSubmit={handleSubmit(onSubmit)}
					encType="multipart/form-data">
					<div className="mt-10  flex d-flex justify-content-between">
						<label className="font-18"> Title:</label>
						<button
							type="button"
							className="btn btn-danger"
							onClick={(e) => props.hideForm()}>
							<span>X</span>
						</button>
					</div>
					<div className="input-wrapper">
						<input
							name="article"
							type="text"
							placeholder="Enter Title Here..."
							className="input"
							{...register("article", {
								onChange: (e) => {
									setFaq({ ...faq, article: e.target.value });
								},
							})}
						/>
					</div>
					<div className="text-danger text-sm ml-3 pt-1">
						{errors.article?.message}
					</div>
					<div className="mt-10 ">
						<label className="font-18"> Meta tags:</label>
					</div>
					<div className="input-wrapper">
						<input
							name="metaTags"
							type="text"
							placeholder="Enter Meta Tags Here..."
							className="input"
							{...register("metaTags", {
								onChange: (e) => {
									setFaq({ ...faq, metaTags: e.target.value });
								},
							})}
						/>
					</div>
					<div className="text-danger text-sm ml-3 pt-1">
						{errors.metaTags?.message}
					</div>
					<div className="mt-10 ">
						<label className="font-18"> Date Published:</label>
					</div>
					<div className="input-wrapper">
						<input
							name="datePublished"
							type="date"
							placeholder="Enter Meta Tags Here"
							className="input"
							value={faq.datePublished}
							{...register("datePublished", {
								onChange: (e) => {
									setFaq({ ...faq, datePublished: e.target.value });
								},
							})}
						/>
					</div>
					<div className="text-danger text-xs ml-3 pt-1">
						{errors.datePublished?.message}
					</div>
					<div className="mt-20 ">
						<label className="font-18"> Description:</label>
					</div>
					<div className="">
						<textarea
							className="input p-10"
							placeholder="Article Description and content..."
							rows={4}
							width="100%"
							cols={96}
							{...register("description", {
								onChange: (e) => {
									setFaq({ ...faq, description: e.target.value });
								},
							})}></textarea>
					</div>
					<div className="text-danger text-xs ml-3 pt-1">
						{errors.description?.message}
					</div>

					<VideoAdd
						register={register}
						errors={errors}
						defaultFieldVideoURL={defaultFieldVideoURL}
						videoUrls={videoUrls}
						setVideoUrls={setVideoUrls}
						appendVideoURL={appendVideoURL}
						removeVideoURL={removeVideoURL}
						videoRequired={true}
					/>
					<ImageAdd
						register={register}
						errors={errors}
						fields={fields}
						append={append}
						remove={remove}
						setFields={setFields}
						defaultField={defaultField}
						imageRequired={true}
					/>

					<div className="save-cancel-wrapper">
						<div>
							<button type="submit" className="btn btn-success">
								<span>PUBLISH</span>
							</button>
						</div>
						<div>
							<button
								type="button"
								className="btn btn-danger"
								onClick={(e) => props.hideForm()}>
								<span>CANCEL</span>
							</button>
						</div>
					</div>
				</form>
			</div>
		</>
	);
};

export default memo(AddFaq);
